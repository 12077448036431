/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    ul: "ul",
    li: "li",
    strong: "strong",
    img: "img"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, LandingPageCta} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-loss/sudden/'], ['en', 'https://www.hear.com/hearing-loss/sudden/'], ['en-US', 'https://www.hear.com/hearing-loss/sudden/'], ['en-CA', 'https://ca.hear.com/hearing-loss/sudden/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "what-is-sudden-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-is-sudden-hearing-loss",
    "aria-label": "what is sudden hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What is Sudden Hearing Loss?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "As the name implies, sudden hearing loss can occur suddenly or develop within a few hours. It is still unclear what exactly causes sudden hearing loss, and the medical world is searching for ways to better understand this phenomenon."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The effects of sudden hearing loss range from mild to severe depending on other health factors, and can potentially become a permanent condition. Sudden hearing loss may also occur together with tinnitus. We recommend that you consult a doctor immediately if you suspect that you are experiencing sudden hearing loss. If symptoms persist for an extended period of time, hearing aids can provide effective relief."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-causes-sudden-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-causes-sudden-hearing-loss",
    "aria-label": "what causes sudden hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What Causes Sudden Hearing Loss?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The exact cause of sudden hearing loss is unknown. Nevertheless, we do know that circulation in the inner ear is reduced during sudden hearing loss. Therefore, one theory for its cause is stress. A hectic environment or severe time pressure at work could lead to circulatory disorders, potentially leading to sudden hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Other possible causes of sudden hearing loss include:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Viral infections or reactivations (e.g. herpes or chickenpox)"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Arteriosclerosis and subsequent circulatory disorders"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Metabolic disorders, such as diabetes or high cholesterol"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "A ruptured oval or round window"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Cervical spine injury (e.g. whiplash)"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Elevated platelet aggregation (blood clots)"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Occlusion (thrombosis) of the inner ear’s vessels"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Autoimmune diseases"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Previous acute otitis media (middle ear infection)"), "\n"), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "types-of-sudden-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#types-of-sudden-hearing-loss",
    "aria-label": "types of sudden hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Types of Sudden Hearing Loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Sudden idiopathic hearing loss")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Idiopathic means “without recognizable cause.” This is the most common (and sudden) type of hearing loss. Extensive examination cannot determine an organic cause."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Stress-related sudden hearing loss")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Stress is a frequent trigger for sudden hearing loss. Therefore, it is imperative that you reduce your stress levels so that you can better cope with demanding situations. Regular exercise can be helpful in eliminating stress."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A person’s medical history can play an important role in diagnosing sudden hearing loss, and may contain vital clues that could indicate possible causes of their current hearing difficulty. In addition to medical history, other diagnostic methods, such as Fowler’s pure-tone audiometry, brainstem electrical response audiometry (BERA), or the measurement of otoacoustic emissions (OAE), serve to exclude a diseased acoustic nerve as the cause of impaired hearing."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-symptoms-of-sudden-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-symptoms-of-sudden-hearing-loss",
    "aria-label": "the symptoms of sudden hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The Symptoms of Sudden Hearing Loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/resources/hearing-aid-success/pressure",
    className: "c-md-a"
  }, "Ear pressure"), " and/or tinnitus are typically the first signs of sudden hearing loss. Symptoms occur at once or within a few days, usually in one ear, and can vary in severity. In worst case scenarios, permanent deafness is possible."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.img, {
    src: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/inside-of-ear-cochlea-canal.jpg",
    alt: "Cochlea canal",
    className: "c-md-img"
  })), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/resources/hearing-loss/earache",
    className: "c-md-a"
  }, "Earache"), " is not a common symptom of sudden hearing loss. Pain in one ear has different causes and may indicate another clinical issue, such as an infection. However, the occurrence of a muffled sound in the ear or dizzy spells may be a symptom of hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The most common symptoms of sudden hearing loss include:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Occurrence of hearing loss without a recognizable cause"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "The absence of an earache"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Hearing loss in only one ear"), "\n"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Accompanying symptoms include:"), "\n", React.createElement(_components.ul, {
    className: "c-md-list"
  }, "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Dizziness"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Loss of sensation in the outer ear"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Ear pressure"), "\n", React.createElement(_components.li, {
    className: "c-md-li"
  }, "Tinnitus"), "\n"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "diagnosing-sudden-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#diagnosing-sudden-hearing-loss",
    "aria-label": "diagnosing sudden hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Diagnosing Sudden Hearing Loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A diagnosis for sudden hearing loss is determined through process of elimination. Various examination procedures (e.g. blood pressure measurement, blood tests, ear microscopy or ultrasound, and MRIs) are all performed to obtain the best results. For example, an ENT doctor can use otoscopy to discover obstructions or injuries in the ear canal. By ", React.createElement(_components.a, {
    href: "/hearing-loss/audiometry/",
    className: "c-md-a"
  }, "using hearing tests (tuning fork test or pure-tone audiometry)"), ", a doctor can determine the extent and localization (inner or middle ear) of your hearing loss, and classify your sudden hearing loss into high, mid, or low frequency. A pure-tone audiogram is performed during the diagnostic process to determine if you have pan cochlear sensorineural hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "How hearing aids can help in the event of sudden hearing loss")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Wearing hearing aids will alleviate the effects of sudden hearing loss if your hearing loss persists over time. Ideally, the appropriate medication should be administered within 24 hours after you notice the symptoms. If treatment does not follow within this period, and your hearing loss becomes chronic, we recommend the use of hearing aids to provide relief. If your sudden hearing loss occurs with tinnitus, the ‘noiser’ function in most hearing aids can help treat both conditions."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "sudden-hearing-loss-treatment",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#sudden-hearing-loss-treatment",
    "aria-label": "sudden hearing loss treatment permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Sudden Hearing Loss Treatment"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Shortly after experiencing sudden hearing loss, your doctor will want to focus on reducing some risk factors – such as obesity, high blood pressure, high cholesterol levels, and diabetes. You’ll also need to take steps to ensure the reduction of stress in your personal life. If your sudden hearing loss began more than a year ago, the only option left is to alleviate it with the help of hearing aids."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "However, the earlier you start treatment, the better the prognosis. In a best-case scenario, you would see an ENT doctor within the first 24 hours and get help right away. Treatment for sudden hearing loss involves stimulation of inner ear circulation and the elimination of possible triggers. Other forms of treatment include:"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Circulation-enhancing (rheological) medication")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In the event of sudden hearing loss, the inner ear’s blood circulation is reduced or impaired. Blood supplies the inner ear with required nutrients, and if circulation is inhibited, the sensory hair cells of the ear are permanently damaged. Circulation can be improved with circulation-enhancing medication, which can also include cortisone to prevent any swelling (anti-edema treatment)."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Local anesthesia to block inhibited nerves")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Local anesthetics like procaine and lidocaine are frequently used by a medical professional to treat sudden hearing loss. These anesthetics block nerves that potentially lead to vascular constriction."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Hearing aids")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Sudden hearing loss causes permanent damage to cells in your ears, which could potentially lead to permanent hearing loss. Hearing loss that is combined with tinnitus should be treated with hearing aids that generate a soft noise to distract the individual experiencing tinnitus symptoms. This function is referred to as a ‘noiser’ and is ideal for very quiet hearing environments."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "For particularly severe cases")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Dialysis (apheresis) is performed if the sudden hearing loss is particularly severe because during dialysis, the blood is cleansed of coagulation-promoting substances. This drastically improves blood flow and circulation, which helps alleviate sudden hearing loss."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "In the event of obesity and/or diabetes")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Treating arteriosclerosis (the build-up of fats, cholesterol, and other substances in and on artery walls) is extremely important. Lifestyle changes are also necessary to treat sudden hearing loss, including: weight loss (a low-fat diet and exercise), adjustment of blood sugar levels, as well as avoiding nicotine, coffee, and alcohol. These lifestyle changes are imperative for effective treatment."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "complications-following-sudden-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#complications-following-sudden-hearing-loss",
    "aria-label": "complications following sudden hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Complications Following Sudden Hearing Loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Sudden hearing loss may result in various complications, including persistent or permanent hearing loss or complete deafness. Another possible long-term complication is the development of tinnitus, which can also be accompanied by a permanent feeling of pressure in the ear. In some cases, sudden hearing loss is followed by a feeling of internal restlessness and a racing heartbeat."), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
